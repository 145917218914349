import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      whiteLabel: 'GET_WHITE_LABEL_DATA',
      marketerId: 'GET_MARKETER_ID',
      marketerAgencyContent: 'GET_MARKETER_AGENCY_CONTENT',
      marketerAgency: 'GET_MARKETER_AGENCY',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      device: 'GET_DEVICE',
      mediaSize: 'GET_MEDIA_SIZE',
      lang: 'GET_LANGUAGE',
      isEmalonMode: 'GET_IS_EMALON_MODE',
      isUrlContainGoogle: 'GET_URL_CONTAIN_GOOGLE',
    }),
    isMarketer() {
      return this.marketerId !== '';
    },
    kindTheme() {
      const { name } = this.$route;
      let theme = 0;
      const dmstcData = this.marketerAgencyContent?.marketerSpecificContents ?? null;
      if (dmstcData?.useAlternateTemplate) {
        let templateData = null;
        switch (name) {
          case 'home':
            templateData = dmstcData.pageTemplates.find((item) => item.page === 0);
            theme = templateData?.selectedTemplate ?? 0;
            break;
          case 'search-result':
            templateData = dmstcData.pageTemplates.find((item) => item.page === 1);
            theme = templateData?.selectedTemplate ?? 0;
            break;
          case 'product-page':
            templateData = dmstcData.pageTemplates.find((item) => item.page === 2);
            theme = templateData?.selectedTemplate ?? 0;
            break;
          default:
            theme = 0;
        }
      } else if ((name === 'home' || name === 'search-result') && !this.isOdysseySite && !this.isMarketer && this.mediaSize === 'small-size') {
        theme = 2;
      } else {
        theme = 0;
      }

      // set theme to 0 if emalon mode forcely
      if (this.isEmalonMode) {
        theme = 0;
      }

      return theme;
    },
    hostData() {
      let currHostData = null;
      if (this.marketerId !== '') {
        currHostData = this.marketerAgencyContent;
      } else if (this.whiteLabel && !this.whiteLabel.forMainSite) {
        currHostData = this.whiteLabel;
      }
      return currHostData;
    },
    phoneNumber() {
      let number = this.hostData?.phoneNumber || this.whiteLabel.phoneNumber;
      if (this.isEmalonMode && this.isUrlContainGoogle) {
        number = '0508092911';
      }
      return number;
    },
  },
};
